import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Link,
  Box,
  Section,
  Icon,
  Span,
  Image,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { IoIosCheckmarkCircle } from "react-icons/io"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Tróia Golf Club</title>
        <meta
          name={"description"}
          content={"Onde cada balanço conta uma história"}
        />
        <meta property={"og:title"} content={"Home | Tróia Golf Club"} />
        <meta
          property={"og:description"}
          content={"Onde cada balanço conta uma história"}
        />
        <meta
          property={"og:image"}
          content={"https://amirevox.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://amirevox.com/img/001-icon-golf.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="linear-gradient(0deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.4) 100%),url(https://amirevox.com/img/1.jpg) center center/cover no-repeat"
        min-height="80vh"
        quarkly-title="Hero-21"
        padding="88px 0 88px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          flex-wrap="wrap"
          align-items="center"
          justify-content="flex-start"
        />
        <Text
          letter-spacing="2px"
          margin="0px 0px 16px 0px"
          color="--greyD2"
          font="--base"
          lg-width="100%"
          lg-text-align="center"
          text-align="center"
          text-transform="uppercase"
        >
          Deixe a sua história de golfe começar com o Tróia
        </Text>
        <Text
          color="--primary"
          font="--headline1"
          sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          margin="0px 0px 42px 0px"
          lg-width="100%"
          md-width="100%"
          lg-text-align="center"
          lg-margin="0px 0px 20px 0px"
          lg-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          text-align="center"
          letter-spacing="30px"
        >
          Tróia Golf Club
        </Text>
        <Text
          lg-text-align="center"
          text-align="center"
          width="80%"
          margin="0px 0px 36px 0px"
          color="--darkL2"
          font="--lead"
          lg-width="100%"
        >
          Situado no meio de colinas ondulantes e paisagens pitorescas, o nosso
          clube oferece uma fuga para um mundo onde o jogo de golfe não é apenas
          jogado, mas celebrado.
        </Text>
        <Box display="flex" sm-flex-direction="column">
          <Link
            color="--light"
            font="--lead"
            sm-margin="0px 0px 20px 0px"
            sm-width="100%"
            hover-color="--dark"
            border-width="2px"
            border-style="solid"
            href="/contacts"
            text-decoration-line="initial"
            border-radius="75px"
            background="--color-primary"
            border-color="--color-primary"
            padding="10px 23px 10px 23px"
            margin="0px 24px 0px 0px"
            transition="background-color 0.3s linear 0s"
            hover-background="rgba(4, 8, 12, 0)"
            sm-text-align="center"
          >
            Contate-nos
          </Link>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" quarkly-title="Content-8">
        <Override
          slot="SectionContent"
          padding="0px 0 0px 0px"
          lg-padding="0px 0 0px 0px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          lg-grid-template-columns="1fr"
          align-items="start"
          width="100%"
          min-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          padding="0px 60px 0px 60px"
          lg-margin="0px 0px 50px 0px"
          sm-padding="0px 25px 0px 25px"
        >
          <Text
            margin="0px 0px 30px 0px"
            font="normal 600 42px/1.2 --fontFamily-sans"
          >
            Porquê escolher o Tróia?
          </Text>
          <Text
            margin="0px 0px 25px 0px"
            font="normal 300 25px/1.5 --fontFamily-sans"
            color="#505257"
          >
            O Tróia Golf Club é um farol para aqueles que apreciam os aspectos
            mais requintados do mundo do golfe. Com as nossas instalações topo
            de gama, greens meticulosamente mantidos e um clubhouse que irradia
            calor e hospitalidade, garantimos que cada visita é memorável. O
            nosso compromisso com a excelência é evidente em cada fairway e
            green, prometendo uma experiência de golfe inigualável.
          </Text>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
            margin="0px 0px 20px 0px"
          >
            <Icon
              category="io"
              icon={IoIosCheckmarkCircle}
              size="24px"
              color="--primary"
              margin="0px 10px 0px 0px"
              padding="5px 0px 0px 0px"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="300 18px/27px --fontFamily-sans"
              color="#505257"
            >
              <Span
                font='400 18px/27px Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif'
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
              >
                Condições impecáveis:{"  "}
              </Span>
              Os nossos greens são mantidos durante todo o ano, oferecendo
              condições de jogo perfeitas, independentemente da estação do ano.
            </Text>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
            margin="0px 0px 20px 0px"
          >
            <Icon
              category="io"
              icon={IoIosCheckmarkCircle}
              size="24px"
              color="--primary"
              margin="0px 10px 0px 0px"
              padding="5px 0px 0px 0px"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="300 18px/27px --fontFamily-sans"
              color="#505257"
            >
              <Span
                font='400 18px/27px Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif'
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
              >
                Instalações excepcionais:{"  "}
              </Span>
              Desde a nossa loja profissional abastecida com o mais recente
              equipamento até ao nosso convidativo clubhouse, tudo o que precisa
              está ao seu alcance.
            </Text>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
            margin="0px 0px 35px 0px"
          >
            <Icon
              category="io"
              icon={IoIosCheckmarkCircle}
              size="24px"
              color="--primary"
              margin="0px 10px 0px 0px"
              padding="5px 0px 0px 0px"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="300 18px/27px --fontFamily-sans"
              color="#505257"
            >
              <Span
                font='400 18px/27px Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif'
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
              >
                Comunidade e camaradagem:{" "}
              </Span>
              O Tróia é mais do que um simples clube, é uma comunidade onde os
              membros partilham o amor pelo jogo e criam amizades duradouras.
            </Text>
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          padding="0px 0px 0px 60px"
          margin="0px -10px 0px 0px"
          lg-margin="0px 10px 0px 10px"
          lg-padding="0px 0px 0px 0"
        >
          <Image
            src="https://amirevox.com/img/2.jpg"
            display="block"
            border-radius="25px"
          />
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-7"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
        >
          <Box
            overflow-x="hidden"
            transform="translateY(0px)"
            padding="0px 0px 100% 0px"
            width="100%"
            overflow-y="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            height="auto"
          >
            <Image
              src="https://amirevox.com/img/3.jpg"
              object-fit="cover"
              position="absolute"
              width="100%"
              bottom={0}
              display="block"
              top={0}
              left={0}
              right={0}
              min-height="100%"
            />
          </Box>
        </Box>
        <Box
          flex-wrap="wrap"
          flex-direction="row"
          lg-align-items="center"
          padding="4px 4px 4px 4px"
          lg-order="1"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          width="50%"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
        >
          <Box width="50%" padding="12px 12px 12px 13px" display="flex">
            <Box
              width="100%"
              height="auto"
              position="relative"
              transition="transform 0.2s ease-in-out 0s"
              margin="0px 0px 0px 0px"
              overflow-x="hidden"
              overflow-y="hidden"
              transform="translateY(0px)"
              hover-transform="translateY(-10px)"
              padding="0px 0px 100% 0px"
            >
              <Image
                top="auto"
                left={0}
                right={0}
                bottom="0px"
                display="block"
                object-fit="cover"
                position="absolute"
                width="100%"
                min-height="100%"
                src="https://amirevox.com/img/4.jpg"
              />
            </Box>
          </Box>
          <Box padding="12px 12px 12px 12px" display="flex" width="50%">
            <Box
              transition="transform 0.2s ease-in-out 0s"
              hover-transform="translateY(-10px)"
              position="relative"
              transform="translateY(0px)"
              overflow-x="hidden"
              overflow-y="hidden"
              margin="0px 0px 0px 0px"
              padding="0px 0px 100% 0px"
              width="100%"
              height="auto"
            >
              <Image
                bottom="0px"
                src="https://amirevox.com/img/5.jpg"
                position="absolute"
                top="auto"
                left={0}
                min-height="100%"
                object-fit="cover"
                display="block"
                width="100%"
                right={0}
              />
            </Box>
          </Box>
          <Box width="50%" padding="12px 12px 12px 12px" display="flex">
            <Box
              margin="0px 0px 0px 0px"
              padding="0px 0px 100% 0px"
              transition="transform 0.2s ease-in-out 0s"
              height="auto"
              overflow-x="hidden"
              overflow-y="hidden"
              position="relative"
              transform="translateY(0px)"
              hover-transform="translateY(-10px)"
              width="100%"
            >
              <Image
                object-fit="cover"
                display="block"
                top="auto"
                right={0}
                bottom="0px"
                min-height="100%"
                src="https://amirevox.com/img/6.jpg"
                position="absolute"
                width="100%"
                left={0}
              />
            </Box>
          </Box>
          <Box width="50%" padding="12px 12px 12px 12px" display="flex">
            <Box
              position="relative"
              hover-transform="translateY(-10px)"
              padding="0px 0px 100% 0px"
              transform="translateY(0px)"
              transition="transform 0.2s ease-in-out 0s"
              margin="0px 0px 0px 0px"
              width="100%"
              height="auto"
              overflow-x="hidden"
              overflow-y="hidden"
            >
              <Image
                src="https://amirevox.com/img/7.jpg"
                position="absolute"
                display="block"
                top="auto"
                left={0}
                min-height="100%"
                object-fit="cover"
                width="100%"
                right={0}
                bottom="0px"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Entre em contacto
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Pronto para experimentar a magia de Tróia? Entre em contacto
            connosco e vamos começar a sua viagem.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Contate-nos
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://amirevox.com/img/1.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
